<div class="tour-dialog">
  <div class="close">
    <button type="button" mat-icon-button [mat-dialog-close]="'cancel'">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <div class="header-img">
    <img src="/assets/img/oeo.svg" alt="" />
  </div>
  <mat-dialog-content>
    <h3>{{ 'TOUR.title' | translate }}</h3>
    <span class="mat-body-2">{{ 'TOUR.tourDescription' | translate }}</span>
  </mat-dialog-content>
  <mat-dialog-actions class="col">
    <div>
      <button mat-flat-button color="primary" [mat-dialog-close]="'start'">{{ 'TOUR.start' | translate }}</button>
    </div>
    <div class="margin padding s6 top">
      <a class="tour-step-footer mat-caption" (click)="dismissDialog()">{{ 'TOUR.optOut' | translate }}</a>
    </div>
  </mat-dialog-actions>
</div>
