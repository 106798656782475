<span
  *ngIf="column?.tooltip && (data?.actualValue ?? '').length > 20; else regular"
  [class.notification]="showNotification()"
  [matTooltip]="getToolTipValue()"
  [class]="classes"
  data-testid="input-data-with-tooltip"
>
  {{ data['displayValue'] }}
</span>

<ng-template #regular>
  <span
    *ngIf="data?.actualValue; else nonObj"
    [class.notification]="showNotification()"
    [class]="classes"
    data-testid="input-data-ragular-non-obj"
  >
    {{ data['displayValue'] }}
  </span>
  <ng-template #nonObj>
    <span
      [class.notification]="showNotification()"
      [class]="classes"
      data-testid="input-data-ragular-non-obj"
      [matTooltip]="getToolTipValue()"
    >
      {{ dataIsEmptyObj ? '' : data }}
    </span>
  </ng-template>
</ng-template>
