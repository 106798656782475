import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { TourGuideService } from '../../../services/tour-guide/tour-guide.service'
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface'
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu'
import { TourDialogComponent } from '../tour-dialog/tour-dialog.component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MaterialModule } from '../../../../../../../libs/common/src'
import oeoTours from '../../../../../../../apps/online-ordering/src/assets/oeoTours.json'
import { Router } from '@angular/router'
import { EstimatesDialogComponent } from '../../../estimates/components/estimates-dialog/estimates-dialog.component'
import { EstimatesService } from '../../../services/estimates/estimates.service'
import { Subject, take } from 'rxjs'

export interface Tour {
  tourName: string
  steps: IMdStepOption[]
}

@Component({
  selector: 'oeo-tour-guide',
  standalone: true,
  imports: [TourMatMenuModule, CommonModule, MatButtonModule, MaterialModule],
  templateUrl: './tour-guide.component.html',
  styleUrls: ['./tour-guide.component.scss']
})
export class TourGuideComponent implements OnDestroy, OnInit {
  private destroyed$ = new Subject<void>()
  private _showDialog: boolean = false

  overviewTourContent: Tour
  bookmarkTourContent: Tour
  activateTour: boolean = true
  dialogRef: MatDialogRef<EstimatesDialogComponent>

  constructor(
    public router: Router,
    public tourGuideService: TourGuideService,
    public dialog: MatDialog,
    public tourService: TourService,
    public estimatesService: EstimatesService
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  ngOnInit(): void {
    this.handleShowDialogChange(this.showDialog)
  }

  @Input()
  get showDialog(): boolean {
    return this._showDialog
  }

  set showDialog(value: boolean) {
    this._showDialog = value
    this.handleShowDialogChange(value)
  }

  handleShowDialogChange(showDialog: boolean): void {
    const isDismissed = localStorage.getItem('dismissDialog') === 'true'
    if (showDialog && !isDismissed && this.activateTour) {
      this.estimatesService.estimatesResult$.pipe(take(1)).subscribe((res) => {
        if (res.count === 0) {
          this.setUpTours()
          this.openTourDialog()
        }
      })
    }
  }

  setUpTours(): void {
    oeoTours.forEach((tour: Tour) => {
      if (tour?.tourName == 'OEO Overview') {
        this.overviewTourContent = tour
      } else if (tour?.tourName == 'Bookmarks') {
        this.bookmarkTourContent = tour
      }
    })
  }

  openTourDialog(): void {
    const dialogRef = this.dialog.open(TourDialogComponent, {
      width: '367px',
      data: {
        /* you can pass data to the dialog if needed */
      }
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'start') {
        this.overviewTour()
      }
    })
  }

  overviewTour(): void {
    this.tourGuideService.start(this.overviewTourContent.steps)
  }

  bookmarkTour(): void {
    this.tourGuideService.start(this.bookmarkTourContent.steps)
  }
}
