<div
  class="main"
  [lang]="lang"
  [ngClass]="{
    landing: router.url === '/landing' && multiBranchEnabled,
    collapsed: storageService.getItem('collapsedNav')
  }"
>
  <div class="side-nav" *ngIf="showSideNav">
    <oa-side-nav></oa-side-nav>
  </div>
  <div class="right">
    <oa-header></oa-header>

    <!-- Internal User Notice -->
    <oa-notification-banner
      *ngIf="(bannerNotifications$ | async).InternalNotification"
      [notificationType]="'InternalNotification'"
    >
      <p class="notification-warning">{{ 'internalNotification' | translate }}</p>
    </oa-notification-banner>
    <!-- Interner Explorer Notice -->
    <oa-notification-banner *ngIf="(bannerNotifications$ | async).IENotification" [notificationType]="'IENotification'">
      <p class="notification-warning">{{ 'ieNotification' | translate }}</p>
    </oa-notification-banner>

    <div class="router" id="router">
      <oeo-tour-guide [showDialog]="showTourGuide"></oeo-tour-guide>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
