<button
  *ngIf="visibleMenuItems"
  mat-icon-button
  type="button"
  [matMenuTriggerFor]="Menu"
  #MenuTrigger="matMenuTrigger"
  [disabled]="column?.disabled || column?.menuItems.length === 0"
  (click)="stopPropagation($event)"
  [matTooltip]="column?.tooltip | translate"
  attr.aria-label="{{ 'moreActions' | translate }}"
  data-testid="menu-button"
>
  <mat-icon svgIcon="actions_menu"></mat-icon>
</button>

<mat-menu #Menu>
  <ng-container *ngFor="let item of column?.menuItems">
    <ng-container
      *ngTemplateOutlet="item.cutsheet ? CutSheet : MenuItemButton; context: { $implicit: item }"
    ></ng-container>
  </ng-container>
</mat-menu>

<ng-template #MenuItemButton let-item>
  <button
    *ngIf="!isHidden(item) && !(item.menuItemConditions?.hide && item.menuItemConditions?.hide(row))"
    mat-menu-item
    type="button"
    (click)="stopPropagation($event); handleClick(item, $event)"
    ovtTrack
    [ovtCategory]="item.ovtCategory"
    [ovtAction]="item.ovtAction"
    [ovtProperties]="item?.ovtProperties ?? {}"
    [disabled]="isDisabled(item, row)"
  >
    <mat-icon [svgIcon]="item.icon || item.menuItemConditions?.getIcon(row)"></mat-icon>
    {{ item.label || item.menuItemConditions?.getLabel(row) | translate }}
  </button>
</ng-template>

<ng-template #CutSheet let-item>
  <button
    *ngIf="
      !(item.menuItemConditions?.hide && item.menuItemConditions?.hide(row)) &&
      (!row?.cutSheets || row?.cutSheets.length < 2)
    "
    mat-menu-item
    type="button"
    [disabled]="!row.cutSheets || row.cutSheets.length === 0"
    (click)="stopPropagation($event); handleClick(item, $event, row.cutSheets[0])"
    ovtTrack
    [ovtCategory]="item.ovtCategory"
    ovtAction="OpenCutSheet"
  >
    <mat-icon svgIcon="blueprint"></mat-icon>
    {{ 'cutSheet' | translate }}
  </button>

  <button
    *ngIf="row.cutSheets && row.cutSheets.length > 1"
    mat-menu-item
    type="button"
    [matMenuTriggerFor]="CutSheetMenu"
    ovtTrack
    [ovtCategory]="item.ovtCategory"
    ovtAction="OpenCutSheet"
  >
    <mat-icon svgIcon="blueprint"></mat-icon>
    {{ 'cutSheets' | translate }}
  </button>

  <mat-menu #CutSheetMenu>
    <button
      *ngFor="let cutSheet of row.cutSheets"
      type="button"
      (click)="stopPropagation($event); handleClick(item, $event, cutSheet)"
      ovtTrack
      [ovtCategory]="item.ovtCategory"
      ovtAction="OpenCutSheet"
    >
      <mat-icon svgIcon="blueprint"></mat-icon>
      {{ cutSheet?.description | translate }}
    </button>
  </mat-menu>
</ng-template>
