import { Injectable } from '@angular/core'
import { TourService } from 'ngx-ui-tour-md-menu'
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface'
import { MatDialog } from '@angular/material/dialog'

@Injectable({
  providedIn: 'root'
})
export class TourGuideService {
  constructor(private readonly tourService: TourService, public dialog: MatDialog) {}

  start(steps: IMdStepOption[]) {
    this.tourService.initialize(steps)
    this.tourService.start()
  }

  isDismissed() {
    return localStorage.getItem('dismissDialog') === 'true'
  }
}
