<span>
  <button
    *ngIf="!column?.buttonConfig?.iconOnly"
    mat-button
    type="button"
    class="oa-button"
    [ngClass]="column?.buttonConfig?.buttonClass"
    (click)="onClick($event)"
    [disabled]="column?.buttonConfig?.disabled || (row && row.marks_disabled)"
    ovtTrack
    [ovtCategory]="column?.buttonConfig?.ovtCategory"
    [ovtAction]="column?.buttonConfig?.ovtAction"
    [ovtProperties]="column?.buttonConfig?.ovtProperties ?? {}"
    [matTooltip]="getToolTipValue() | translate"
    [matTooltipPosition]="'before'"
    data-testid="button-element"
  >
    <ng-container *ngIf="column?.buttonConfig?.icon">
      <ng-container *ngIf="!column.buttonConfig?.materialIcon; else materialIcon">
        <mat-icon svgIcon="{{ column?.buttonConfig?.icon }}"></mat-icon>
      </ng-container>
    </ng-container>
    <span [class]="classes">
      {{ data?.toString() || (column?.buttonConfig?.defaultText ?? '') | translate }}
    </span>
  </button>

  <button
    mat-icon-button
    [matTooltip]="getToolTipValue() | translate"
    *ngIf="column?.buttonConfig?.iconOnly"
    (click)="onClick($event)"
    [tourAnchor]="rowIndex === 0 ? column.buttonConfig?.tourAnchor || '' : ''"
  >
    <ng-container *ngIf="!column.buttonConfig?.materialIcon; else iconOnlyMat">
      <mat-icon svgIcon="{{ column.buttonConfig?.icon }}"></mat-icon>
    </ng-container>
  </button>

  <mat-hint *ngIf="warningMessages.length > 0" data-testid="warning-messages">
    {{ warningMessages[0] | translate }}
  </mat-hint>
</span>

<ng-template #iconOnlyMat>
  <mat-icon class="oa-icon-button" data-testid="icon-element">{{ column.buttonConfig?.iconInput(row) }}</mat-icon>
</ng-template>

<ng-template #materialIcon>
  <mat-icon
    *ngIf="data || (column?.notification && row?.hasNotification()) || column.buttonConfig?.materialIcon"
    [color]="column?.color"
    data-testid="icon-element"
    >{{ column.buttonConfig?.icon }}</mat-icon
  >
</ng-template>
